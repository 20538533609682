import { Icon } from '@wla/components/ui/icon';

type DefaultTriggerProps = {
  title: string;
  open: boolean;
};

export function DefaultTrigger({ title, open }: DefaultTriggerProps) {
  return (
    <div className="flex items-center">
      <p className="text-start text-brand-500 underline">{title}</p>
      {open ? <Icon src="w3-arrow-up" /> : <Icon src="w3-arrow-down" />}
    </div>
  );
}
