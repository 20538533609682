'use client';
import { Disclosure, DisclosureButton, DisclosurePanel, Transition } from '@headlessui/react';
import { DefaultTrigger } from '@wla/components/ui/collapsible/collapsible-default-trigger';
import { Icon } from '@wla/components/ui/icon';
import { cn } from '@wla/lib/helpers/cn';
import { ReactNode, useEffect, useState } from 'react';

type BaseCollapsibleProps = {
  title: string;
  isSpecifications?: boolean;
  className?: string;
  wrapperClassName?: string;
  defaultOpen?: boolean;
};

type CollapsibleProps = BaseCollapsibleProps & {
  children: ReactNode;
};

type CollapsibleButtonProps = BaseCollapsibleProps & {
  open?: boolean;
};

export function CollapsibleButton({
  title,
  isSpecifications,
  className,
  wrapperClassName,
  open = false,
}: CollapsibleButtonProps) {
  if (isSpecifications) {
    return <DefaultTrigger title={title} open={open} />;
  }

  return (
    <div className={cn('flex w-full items-center justify-between', wrapperClassName)}>
      <span className={cn('text-lg', className)}>{title}</span>
      <Icon
        src="w3-arrow-down"
        size="24"
        className={cn('w-fit transform font-normal transition-transform', { 'rotate-180': open })}
      />
    </div>
  );
}

export function Collapsible({
  title,
  children,
  isSpecifications,
  className,
  wrapperClassName,
  defaultOpen = false,
}: CollapsibleProps) {
  const [open, setOpen] = useState(defaultOpen);

  useEffect(() => {
    setOpen(defaultOpen);
  }, [defaultOpen]);

  return (
    <Disclosure defaultOpen={defaultOpen}>
      <DisclosureButton className={cn({ 'w-full': !isSpecifications })} onClick={() => setOpen(!open)}>
        <CollapsibleButton
          title={title}
          isSpecifications={isSpecifications}
          open={open}
          className={className}
          wrapperClassName={wrapperClassName}
        />
      </DisclosureButton>
      <Transition
        as="div"
        show={open}
        enter="transition-[grid-template-rows] duration-500 ease-in-out"
        enterFrom="grid-rows-[0fr]"
        enterTo="grid-rows-[1fr]"
        leave="transition-[grid-template-rows] duration-500 ease-in-out"
        leaveFrom="grid-rows-[1fr]"
        leaveTo="grid-rows-[0fr]"
        className="grid"
      >
        <DisclosurePanel className="w-full cursor-default overflow-hidden">{children}</DisclosurePanel>
      </Transition>
    </Disclosure>
  );
}
