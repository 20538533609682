'use client';

import { Disclosure, DisclosureButton, DisclosurePanel, Transition } from '@headlessui/react';
import { Icon } from '@wla/components/ui/icon';
import { Link } from '@wla/components/ui/link';
import { cn } from '@wla/lib/helpers/cn';
import { Fragment } from 'react';
import { Menu } from '@jysk/api-types/drupalApi';

type SideNavigationMenuProps = {
  menu: Menu;
  currentUrl: string;
};

export function SideNavigationMenu({ menu, currentUrl }: SideNavigationMenuProps) {
  if (menu.items?.length === 0) return null;

  return (
    <div className="flex w-full flex-col">
      {menu.items?.map((parentItem) => (
        <>
          <div className="flex w-full flex-col border-b py-4">
            <div className="flex w-full overflow-hidden hyphens-auto">
              <Link
                href={parentItem.url}
                target={parentItem.target ?? '_self'}
                title={parentItem.description ?? ''}
                className={cn('text-2xl font-semibold text-black no-underline hover:text-black', {
                  'w-full': !parentItem.children,
                  'w-11/12': parentItem.children && parentItem.children.length > 0,
                })}
              >
                {parentItem.title}
              </Link>
            </div>
          </div>
          {parentItem.children?.map((item, index) => (
            <Disclosure
              key={index}
              defaultOpen={
                currentUrl.includes(item.url) || Boolean(item.children?.find((child) => child.url === currentUrl))
              }
            >
              {(panel) => {
                const { open } = panel;
                return (
                  <>
                    <div className="flex w-full flex-col border-b py-4">
                      <div className="flex w-full overflow-hidden hyphens-auto">
                        <Link
                          href={item.url}
                          target={item.target ?? '_self'}
                          title={item.description ?? ''}
                          className={cn('text-lg text-black no-underline hover:text-black', {
                            'w-full': !item.children,
                            'w-11/12': item.children && item.children.length > 0,
                            'font-semibold':
                              currentUrl === item.url ||
                              item.style === 'bold' ||
                              item.children?.find((child) => child.url === currentUrl),
                            italic: item.style === 'italic',
                          })}
                        >
                          {item.title}
                        </Link>
                        {Boolean(item.children?.length) && (
                          <DisclosureButton className="flex grow cursor-pointer items-center justify-center text-3xl">
                            <Icon src={open ? 'arrow-up' : 'arrow-down'} size="12" />
                          </DisclosureButton>
                        )}
                      </div>
                      <div className="flex w-full">
                        {
                          <Transition
                            as="div"
                            show={open}
                            enter="transition-[grid-template-rows] duration-500 ease-in-out"
                            enterFrom="grid-rows-[0fr]"
                            enterTo="grid-rows-[1fr]"
                            leave="transition-[grid-template-rows] duration-500 ease-in-out"
                            leaveFrom="grid-rows-[1fr]"
                            leaveTo="grid-rows-[0fr]"
                            className="grid"
                          >
                            <DisclosurePanel className="w-full overflow-hidden bg-white text-base">
                              {item.children?.map((subItem) => (
                                <Fragment key={subItem.title}>
                                  <div className="flex w-full overflow-hidden hyphens-auto pt-4">
                                    <Link
                                      href={subItem.url}
                                      target={subItem.target ?? '_self'}
                                      title={subItem.description ?? ''}
                                      className={cn('w-full pl-6 text-lg text-black no-underline hover:text-black', {
                                        'font-bold': currentUrl === subItem.url || subItem.style === 'bold',
                                        italic: subItem.style === 'italic',
                                      })}
                                    >
                                      {subItem.title}
                                    </Link>
                                  </div>
                                </Fragment>
                              ))}
                            </DisclosurePanel>
                          </Transition>
                        }
                      </div>
                    </div>
                  </>
                );
              }}
            </Disclosure>
          ))}
        </>
      ))}
    </div>
  );
}
